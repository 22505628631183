import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../../public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'speakers',
    loadChildren: () => import('../../speakers/speakers.module').then(m => m.SpeakersModule),
  },
  {
    path: 'sponsors',
    loadChildren: () => import('../../sponsors/sponsors.module').then(m => m.SponsorsModule),
  },
  {
    path: 'sessions',
    loadChildren: () => import('../../sessions/sessions.module').then(m => m.SessionsModule),
  },
  {
    path: 'schedule',
    loadChildren: () => import('../../schedule/schedule.module').then(m => m.ScheduleModule),
  },
  {
    path: 'about',
    loadChildren: () => import('../../about/about.module').then(m => m.AboutModule),
    data: { title: 'About' }
  },
  {
    path: 'contact',
    loadChildren: () => import('../../contact/contact.module').then(m => m.ContactModule),
    data: { title: 'Contact' }
  },
  {
    path: 'resources',
    loadChildren: () => import('../../resources/resources.module').then(m => m.ResourcesModule),
    data: { title: 'resources' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
