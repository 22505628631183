import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "./core/store/app.state";
import { BaseModalService } from "./core/services/base-modal.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  constructor(
    private vc: ViewContainerRef,
    private baseModalService: BaseModalService
  ) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.baseModalService.setViewContainer(this.vc);
  }
}
