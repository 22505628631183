import { Component, isStandalone, ViewContainerRef } from '@angular/core';
import { BaseModalService } from '@app/core/services/base-modal.service';

@Component({
  selector: 'app-modal-overlay',
  templateUrl: './modal-overlay.component.html',
  standalone: true
})
export class ModalOverlayComponent {
  constructor(
    public viewContainerRef: ViewContainerRef,
    private baseModalService: BaseModalService,
  ) {}

  handleButtonClick():void {
    this.baseModalService.closeAll();
  }
}
