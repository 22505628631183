import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { ModalOverlayComponent } from '@app/layout/components/modal-components/modal-overlay/modal-overlay.component';
import { ComponentType } from 'ngx-toastr';

type DialogMap = {
  [id: string]: ComponentRef<any>;
};

@Injectable()
export class BaseModalService {
  private vc!: ViewContainerRef;

  dialogMap: DialogMap = {};
  overlayComponentRef!: ComponentRef<ModalOverlayComponent> | null;

  constructor() {}

  open(component: ComponentType<any>) {
    if(!this.overlayComponentRef) {
      this.overlayComponentRef = this.vc.createComponent(ModalOverlayComponent);
    }
    const dialogRef = this.vc.createComponent(component);
    dialogRef.instance.id = 'dialog' + Object.keys(this.dialogMap).length;
    this.dialogMap[dialogRef.instance.id] = dialogRef;
    return dialogRef;
  }

  closeAll():void {
    const keys = Object.keys(this.dialogMap);

    keys.forEach(key => {
      this.close(key);
    });
  }

  close(id: string) {
    this.dialogMap[id].destroy();
    delete this.dialogMap[id];
    if(Object.keys(this.dialogMap).length === 0 && this.overlayComponentRef) {
      this.overlayComponentRef?.destroy();
      this.overlayComponentRef = null;
    }
  }

  setViewContainer(vc: ViewContainerRef) {
    this.vc = vc;
  }
}
