import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './routing/app.routing';
import { AppStoreModule } from './store/app.store';
import { ToastService } from './services/toast.service';
import { BaseModalService } from './services/base-modal.service';


@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AppStoreModule
  ],
  providers: [
    ToastService,
    BaseModalService
  ],
  exports: [
    AppRoutingModule,
    AppStoreModule
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    core: CoreModule
  ) {
    if (core) {
      throw new Error('CoreModule already instantiated!');
    }
  }
}
